import _ from "lodash"
import React from "react"
import marked from "marked";
import { parseRawHtml as parse } from "../utils"

import {
  Container,
  Header,
  Segment,
} from "semantic-ui-react"
import ResponsiveLayout from "../components/responsive-layout"
import SocialShare from "../components/social-share";

import PageContent from "frontmatter-markdown-loader?modules!../../content/about.md"


export default ({ data = PageContent.attributes }) => (
  <ResponsiveLayout title={data.title}>
    <Page content={data} />
  </ResponsiveLayout>
)

const Page = ({ content, isMobile }) => (
  <>
    <Segment basic style={{
      backgroundImage: `url(${content.heroImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: isMobile ? "15em" : "30em",
      margin: "0em",
    }} />
    <Container>
      <Header as="h2" dividing
        style={{
          marginTop: "2em"
        }}
        children={
          <>
            {content.title}
            <SocialShare config={content.social} float="right" />
          </>
        }
      />
      <Segment basic className="MkdContent"
        style={{
          marginTop: "2em",
          padding: "0em"
        }}
        content={parse(marked(content.content))}
      />
    </Container>
  </>
)
